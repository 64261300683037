export const countries = [
  {
    name: 'Afghanistan',
    iso2: 'AF',
    numericCode: '004',
    phoneCode: '93',
    capital: 'Kabul',
    currency: 'AFN',
    masks: ['##-###-####'],
    id: 1,
  },
  {
    name: 'Aland Islands',
    iso2: 'AX',
    numericCode: '248',
    phoneCode: '+358-18',
    capital: 'Mariehamn',
    currency: 'EUR',
    masks: ['(###)###-##-##'],
    id: 2,
  },
  {
    name: 'Albania',
    iso2: 'AL',
    numericCode: '008',
    phoneCode: '355',
    capital: 'Tirana',
    currency: 'ALL',
    masks: ['(###)###-###'],
    id: 3,
  },
  {
    name: 'Algeria',
    iso2: 'DZ',
    numericCode: '012',
    phoneCode: '213',
    capital: 'Algiers',
    currency: 'DZD',
    masks: ['##-###-####'],
    id: 4,
  },
  {
    name: 'American Samoa',
    iso2: 'AS',
    numericCode: '016',
    phoneCode: '+1-684',
    capital: 'Pago Pago',
    currency: 'USD',
    masks: ['(684)###-####'],
    id: 5,
  },
  {
    name: 'Andorra',
    iso2: 'AD',
    numericCode: '020',
    phoneCode: '376',
    capital: 'Andorra la Vella',
    currency: 'EUR',
    masks: ['###-###'],
    id: 6,
  },
  {
    name: 'Angola',
    iso2: 'AO',
    numericCode: '024',
    phoneCode: '244',
    capital: 'Luanda',
    currency: 'AOA',
    masks: ['(###)###-###'],
    id: 7,
  },
  {
    name: 'Anguilla',
    iso2: 'AI',
    numericCode: '660',
    phoneCode: '+1-264',
    capital: 'The Valley',
    currency: 'XCD',
    masks: ['(264)###-####'],
    id: 8,
  },
  {
    name: 'Antarctica',
    iso2: 'AQ',
    numericCode: '010',
    phoneCode: '672',
    capital: '',
    currency: 'AAD',
    masks: ['1##-###'],
    id: 9,
  },
  {
    name: 'Antigua And Barbuda',
    iso2: 'AG',
    numericCode: '028',
    phoneCode: '+1-268',
    capital: 'St. John',
    currency: 'XCD',
    masks: ['(268)###-####'],
    id: 10,
  },
  {
    name: 'Argentina',
    iso2: 'AR',
    numericCode: '032',
    phoneCode: '54',
    capital: 'Buenos Aires',
    currency: 'ARS',
    masks: ['(###)###-####'],
    id: 11,
  },
  {
    name: 'Armenia',
    iso2: 'AM',
    numericCode: '051',
    phoneCode: '374',
    capital: 'Yerevan',
    currency: 'AMD',
    masks: ['##-###-###'],
    id: 12,
  },
  {
    name: 'Aruba',
    iso2: 'AW',
    numericCode: '533',
    phoneCode: '297',
    capital: 'Oranjestad',
    currency: 'AWG',
    masks: ['###-####'],
    id: 13,
  },
  {
    name: 'Australia',
    iso2: 'AU',
    numericCode: '036',
    phoneCode: '61',
    capital: 'Canberra',
    currency: 'AUD',
    masks: ['#-####-####'],
    id: 14,
  },
  {
    name: 'Austria',
    iso2: 'AT',
    numericCode: '040',
    phoneCode: '43',
    capital: 'Vienna',
    currency: 'EUR',
    masks: ['(###)###-####'],
    id: 15,
  },
  {
    name: 'Azerbaijan',
    iso2: 'AZ',
    numericCode: '031',
    phoneCode: '994',
    capital: 'Baku',
    currency: 'AZN',
    masks: ['##-###-##-##'],
    id: 16,
  },
  {
    name: 'The Bahamas',
    iso2: 'BS',
    numericCode: '044',
    phoneCode: '+1-242',
    capital: 'Nassau',
    currency: 'BSD',
    masks: ['(242)###-####'],
    id: 17,
  },
  {
    name: 'Bahrain',
    iso2: 'BH',
    numericCode: '048',
    phoneCode: '973',
    capital: 'Manama',
    currency: 'BHD',
    masks: ['####-####'],
    id: 18,
  },
  {
    name: 'Bangladesh',
    iso2: 'BD',
    numericCode: '050',
    phoneCode: '880',
    capital: 'Dhaka',
    currency: 'BDT',
    masks: ['1###-######'],
    id: 19,
  },
  {
    name: 'Barbados',
    iso2: 'BB',
    numericCode: '052',
    phoneCode: '+1-246',
    capital: 'Bridgetown',
    currency: 'BBD',
    masks: ['(246)###-####'],
    id: 20,
  },
  {
    name: 'Belarus',
    iso2: 'BY',
    numericCode: '112',
    phoneCode: '375',
    capital: 'Minsk',
    currency: 'BYN',
    masks: ['(##)###-##-##'],
    id: 21,
  },
  {
    name: 'Belgium',
    iso2: 'BE',
    numericCode: '056',
    phoneCode: '32',
    capital: 'Brussels',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 22,
  },
  {
    name: 'Belize',
    iso2: 'BZ',
    numericCode: '084',
    phoneCode: '501',
    capital: 'Belmopan',
    currency: 'BZD',
    masks: ['###-####'],
    id: 23,
  },
  {
    name: 'Benin',
    iso2: 'BJ',
    numericCode: '204',
    phoneCode: '229',
    capital: 'Porto-Novo',
    currency: 'XOF',
    masks: ['##-##-####'],
    id: 24,
  },
  {
    name: 'Bermuda',
    iso2: 'BM',
    numericCode: '060',
    phoneCode: '+1-441',
    capital: 'Hamilton',
    currency: 'BMD',
    masks: ['(441)###-####'],
    id: 25,
  },
  {
    name: 'Bhutan',
    iso2: 'BT',
    numericCode: '064',
    phoneCode: '975',
    capital: 'Thimphu',
    currency: 'BTN',
    masks: ['17-###-###', '77-###-###', '#-###-###'],
    id: 26,
  },
  {
    name: 'Bolivia',
    iso2: 'BO',
    numericCode: '068',
    phoneCode: '591',
    capital: 'Sucre',
    currency: 'BOB',
    masks: ['#-###-####'],
    id: 27,
  },
  {
    name: 'Bosnia and Herzegovina',
    iso2: 'BA',
    numericCode: '070',
    phoneCode: '387',
    capital: 'Sarajevo',
    currency: 'BAM',
    masks: ['##-####', '##-#####'],
    id: 28,
  },
  {
    name: 'Botswana',
    iso2: 'BW',
    numericCode: '072',
    phoneCode: '267',
    capital: 'Gaborone',
    currency: 'BWP',
    masks: ['##-###-###'],
    id: 29,
  },
  {
    name: 'Bouvet Island',
    iso2: 'BV',
    numericCode: '074',
    phoneCode: '0055',
    capital: '',
    currency: 'NOK',
    masks: null,
    id: 30,
  },
  {
    name: 'Brazil',
    iso2: 'BR',
    numericCode: '076',
    phoneCode: '55',
    capital: 'Brasilia',
    currency: 'BRL',
    masks: ['(##)####-####', '(##)#####-####'],
    id: 31,
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'IO',
    numericCode: '086',
    phoneCode: '246',
    capital: 'Diego Garcia',
    currency: 'USD',
    masks: ['###-####'],
    id: 32,
  },
  {
    name: 'Brunei',
    iso2: 'BN',
    numericCode: '096',
    phoneCode: '673',
    capital: 'Bandar Seri Begawan',
    currency: 'BND',
    masks: ['###-####'],
    id: 33,
  },
  {
    name: 'Bulgaria',
    iso2: 'BG',
    numericCode: '100',
    phoneCode: '359',
    capital: 'Sofia',
    currency: 'BGN',
    masks: ['(###)###-###'],
    id: 34,
  },
  {
    name: 'Burkina Faso',
    iso2: 'BF',
    numericCode: '854',
    phoneCode: '226',
    capital: 'Ouagadougou',
    currency: 'XOF',
    masks: ['##-##-####'],
    id: 35,
  },
  {
    name: 'Burundi',
    iso2: 'BI',
    numericCode: '108',
    phoneCode: '257',
    capital: 'Bujumbura',
    currency: 'BIF',
    masks: ['##-##-####'],
    id: 36,
  },
  {
    name: 'Cambodia',
    iso2: 'KH',
    numericCode: '116',
    phoneCode: '855',
    capital: 'Phnom Penh',
    currency: 'KHR',
    masks: ['##-###-###'],
    id: 37,
  },
  {
    name: 'Cameroon',
    iso2: 'CM',
    numericCode: '120',
    phoneCode: '237',
    capital: 'Yaounde',
    currency: 'XAF',
    masks: ['####-####'],
    id: 38,
  },
  {
    name: 'Canada',
    iso2: 'CA',
    numericCode: '124',
    phoneCode: '1',
    capital: 'Ottawa',
    currency: 'CAD',
    masks: ['(###)###-####'],
    id: 39,
  },
  {
    name: 'Cape Verde',
    iso2: 'CV',
    numericCode: '132',
    phoneCode: '238',
    capital: 'Praia',
    currency: 'CVE',
    masks: ['(###)##-##'],
    id: 40,
  },
  {
    name: 'Cayman Islands',
    iso2: 'KY',
    numericCode: '136',
    phoneCode: '+1-345',
    capital: 'George Town',
    currency: 'KYD',
    masks: ['(345)###-####'],
    id: 41,
  },
  {
    name: 'Central African Republic',
    iso2: 'CF',
    numericCode: '140',
    phoneCode: '236',
    capital: 'Bangui',
    currency: 'XAF',
    masks: ['##-##-####'],
    id: 42,
  },
  {
    name: 'Chad',
    iso2: 'TD',
    numericCode: '148',
    phoneCode: '235',
    capital: 'Djamena',
    currency: 'XAF',
    masks: ['##-##-##-##'],
    id: 43,
  },
  {
    name: 'Chile',
    iso2: 'CL',
    numericCode: '152',
    phoneCode: '56',
    capital: 'Santiago',
    currency: 'CLP',
    masks: ['#-####-####'],
    id: 44,
  },
  {
    name: 'China',
    iso2: 'CN',
    numericCode: '156',
    phoneCode: '86',
    capital: 'Beijing',
    currency: 'CNY',
    masks: ['(###)####-###', '(###)####-####', '##-#####-#####'],
    id: 45,
  },
  {
    name: 'Christmas Island',
    iso2: 'CX',
    numericCode: '162',
    phoneCode: '61',
    capital: 'Flying Fish Cove',
    currency: 'AUD',
    masks: ['#-####-####'],
    id: 46,
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2: 'CC',
    numericCode: '166',
    phoneCode: '61',
    capital: 'West Island',
    currency: 'AUD',
    masks: ['#-####-####'],
    id: 47,
  },
  {
    name: 'Colombia',
    iso2: 'CO',
    numericCode: '170',
    phoneCode: '57',
    capital: 'Bogotá',
    currency: 'COP',
    masks: ['(###)###-####'],
    id: 48,
  },
  {
    name: 'Comoros',
    iso2: 'KM',
    numericCode: '174',
    phoneCode: '269',
    capital: 'Moroni',
    currency: 'KMF',
    masks: ['##-#####'],
    id: 49,
  },
  {
    name: 'Congo',
    iso2: 'CG',
    numericCode: '178',
    phoneCode: '242',
    capital: 'Brazzaville',
    currency: 'XAF',
    masks: ['##-#####'],
    id: 50,
  },
  {
    name: 'Democratic Republic of the Congo',
    iso2: 'CD',
    numericCode: '180',
    phoneCode: '243',
    capital: 'Kinshasa',
    currency: 'CDF',
    masks: ['(###)###-###'],
    id: 51,
  },
  {
    name: 'Cook Islands',
    iso2: 'CK',
    numericCode: '184',
    phoneCode: '682',
    capital: 'Avarua',
    currency: 'NZD',
    masks: ['##-###'],
    id: 52,
  },
  {
    name: 'Costa Rica',
    iso2: 'CR',
    numericCode: '188',
    phoneCode: '506',
    capital: 'San Jose',
    currency: 'CRC',
    masks: ['####-####'],
    id: 53,
  },
  {
    name: 'Cote D Ivoire (Ivory Coast)',
    iso2: 'CI',
    numericCode: '384',
    phoneCode: '225',
    capital: 'Yamoussoukro',
    currency: 'XOF',
    masks: ['##-###-###'],
    id: 54,
  },
  {
    name: 'Croatia',
    iso2: 'HR',
    numericCode: '191',
    phoneCode: '385',
    capital: 'Zagreb',
    currency: 'HRK',
    masks: ['##-###-###'],
    id: 55,
  },
  {
    name: 'Cuba',
    iso2: 'CU',
    numericCode: '192',
    phoneCode: '53',
    capital: 'Havana',
    currency: 'CUP',
    masks: ['#-###-####'],
    id: 56,
  },
  {
    name: 'Cyprus',
    iso2: 'CY',
    numericCode: '196',
    phoneCode: '357',
    capital: 'Nicosia',
    currency: 'EUR',
    masks: ['##-###-###'],
    id: 57,
  },
  {
    name: 'Czech Republic',
    iso2: 'CZ',
    numericCode: '203',
    phoneCode: '420',
    capital: 'Prague',
    currency: 'CZK',
    masks: ['(###)###-###'],
    id: 58,
  },
  {
    name: 'Denmark',
    iso2: 'DK',
    numericCode: '208',
    phoneCode: '45',
    capital: 'Copenhagen',
    currency: 'DKK',
    masks: ['##-##-##-##'],
    id: 59,
  },
  {
    name: 'Djibouti',
    iso2: 'DJ',
    numericCode: '262',
    phoneCode: '253',
    capital: 'Djibouti',
    currency: 'DJF',
    masks: ['##-##-##-##'],
    id: 60,
  },
  {
    name: 'Dominica',
    iso2: 'DM',
    numericCode: '212',
    phoneCode: '+1-767',
    capital: 'Roseau',
    currency: 'XCD',
    masks: ['(767)###-####'],
    id: 61,
  },
  {
    name: 'Dominican Republic',
    iso2: 'DO',
    numericCode: '214',
    phoneCode: '+1-809 and 1-829',
    capital: 'Santo Domingo',
    currency: 'DOP',
    masks: ['(809)###-####', '(829)###-####', '(849)###-####'],
    id: 62,
  },
  {
    name: 'East Timor',
    iso2: 'TL',
    numericCode: '626',
    phoneCode: '670',
    capital: 'Dili',
    currency: 'USD',
    masks: ['###-####', '77#-#####', '78#-#####'],
    id: 63,
  },
  {
    name: 'Ecuador',
    iso2: 'EC',
    numericCode: '218',
    phoneCode: '593',
    capital: 'Quito',
    currency: 'USD',
    masks: ['#-###-####', '##-###-####'],
    id: 64,
  },
  {
    name: 'Egypt',
    iso2: 'EG',
    numericCode: '818',
    phoneCode: '20',
    capital: 'Cairo',
    currency: 'EGP',
    masks: ['(###)###-####'],
    id: 65,
  },
  {
    name: 'El Salvador',
    iso2: 'SV',
    numericCode: '222',
    phoneCode: '503',
    capital: 'San Salvador',
    currency: 'USD',
    masks: ['##-##-####'],
    id: 66,
  },
  {
    name: 'Equatorial Guinea',
    iso2: 'GQ',
    numericCode: '226',
    phoneCode: '240',
    capital: 'Malabo',
    currency: 'XAF',
    masks: ['##-###-####'],
    id: 67,
  },
  {
    name: 'Eritrea',
    iso2: 'ER',
    numericCode: '232',
    phoneCode: '291',
    capital: 'Asmara',
    currency: 'ERN',
    masks: ['#-###-###'],
    id: 68,
  },
  {
    name: 'Estonia',
    iso2: 'EE',
    numericCode: '233',
    phoneCode: '372',
    capital: 'Tallinn',
    currency: 'EUR',
    masks: ['###-####', '####-####'],
    id: 69,
  },
  {
    name: 'Ethiopia',
    iso2: 'ET',
    numericCode: '231',
    phoneCode: '251',
    capital: 'Addis Ababa',
    currency: 'ETB',
    masks: ['##-###-####'],
    id: 70,
  },
  {
    name: 'Falkland Islands',
    iso2: 'FK',
    numericCode: '238',
    phoneCode: '500',
    capital: 'Stanley',
    currency: 'FKP',
    masks: ['#####'],
    id: 71,
  },
  {
    name: 'Faroe Islands',
    iso2: 'FO',
    numericCode: '234',
    phoneCode: '298',
    capital: 'Torshavn',
    currency: 'DKK',
    masks: ['###-###'],
    id: 72,
  },
  {
    name: 'Fiji Islands',
    iso2: 'FJ',
    numericCode: '242',
    phoneCode: '679',
    capital: 'Suva',
    currency: 'FJD',
    masks: ['##-#####'],
    id: 73,
  },
  {
    name: 'Finland',
    iso2: 'FI',
    numericCode: '246',
    phoneCode: '358',
    capital: 'Helsinki',
    currency: 'EUR',
    masks: ['(###)###-##-##'],
    id: 74,
  },
  {
    name: 'France',
    iso2: 'FR',
    numericCode: '250',
    phoneCode: '33',
    capital: 'Paris',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 75,
  },
  {
    name: 'French Guiana',
    iso2: 'GF',
    numericCode: '254',
    phoneCode: '594',
    capital: 'Cayenne',
    currency: 'EUR',
    masks: ['#####-####'],
    id: 76,
  },
  {
    name: 'French Polynesia',
    iso2: 'PF',
    numericCode: '258',
    phoneCode: '689',
    capital: 'Papeete',
    currency: 'XPF',
    masks: ['##-##-##'],
    id: 77,
  },
  {
    name: 'French Southern Territories',
    iso2: 'TF',
    numericCode: '260',
    phoneCode: '262',
    capital: 'Port-aux-Francais',
    currency: 'EUR',
    masks: null,
    id: 78,
  },
  {
    name: 'Gabon',
    iso2: 'GA',
    numericCode: '266',
    phoneCode: '241',
    capital: 'Libreville',
    currency: 'XAF',
    masks: ['#-##-##-##'],
    id: 79,
  },
  {
    name: 'Gambia The',
    iso2: 'GM',
    numericCode: '270',
    phoneCode: '220',
    capital: 'Banjul',
    currency: 'GMD',
    masks: ['(###)##-##'],
    id: 80,
  },
  {
    name: 'Georgia',
    iso2: 'GE',
    numericCode: '268',
    phoneCode: '995',
    capital: 'Tbilisi',
    currency: 'GEL',
    masks: ['(###)###-###'],
    id: 81,
  },
  {
    name: 'Germany',
    iso2: 'DE',
    numericCode: '276',
    phoneCode: '49',
    capital: 'Berlin',
    currency: 'EUR',
    masks: ['###-###', '(###)##-##', '(###)##-###', '(###)##-####', '(###)###-####', '(####)###-####'],
    id: 82,
  },
  {
    name: 'Ghana',
    iso2: 'GH',
    numericCode: '288',
    phoneCode: '233',
    capital: 'Accra',
    currency: 'GHS',
    masks: ['(###)###-###'],
    id: 83,
  },
  {
    name: 'Gibraltar',
    iso2: 'GI',
    numericCode: '292',
    phoneCode: '350',
    capital: 'Gibraltar',
    currency: 'GIP',
    masks: ['###-#####'],
    id: 84,
  },
  {
    name: 'Greece',
    iso2: 'GR',
    numericCode: '300',
    phoneCode: '30',
    capital: 'Athens',
    currency: 'EUR',
    masks: ['(###)###-####'],
    id: 85,
  },
  {
    name: 'Greenland',
    iso2: 'GL',
    numericCode: '304',
    phoneCode: '299',
    capital: 'Nuuk',
    currency: 'DKK',
    masks: ['##-##-##'],
    id: 86,
  },
  {
    name: 'Grenada',
    iso2: 'GD',
    numericCode: '308',
    phoneCode: '+1-473',
    capital: 'St. Georges',
    currency: 'XCD',
    masks: ['(473)###-####'],
    id: 87,
  },
  {
    name: 'Guadeloupe',
    iso2: 'GP',
    numericCode: '312',
    phoneCode: '590',
    capital: 'Basse-Terre',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 88,
  },
  {
    name: 'Guam',
    iso2: 'GU',
    numericCode: '316',
    phoneCode: '+1-671',
    capital: 'Hagatna',
    currency: 'USD',
    masks: ['(671)###-####'],
    id: 89,
  },
  {
    name: 'Guatemala',
    iso2: 'GT',
    numericCode: '320',
    phoneCode: '502',
    capital: 'Guatemala City',
    currency: 'GTQ',
    masks: ['#-###-####'],
    id: 90,
  },
  {
    name: 'Guernsey and Alderney',
    iso2: 'GG',
    numericCode: '831',
    phoneCode: '+44-1481',
    capital: 'St Peter Port',
    currency: 'GBP',
    masks: ['(####)######'],
    id: 91,
  },
  {
    name: 'Guinea',
    iso2: 'GN',
    numericCode: '324',
    phoneCode: '224',
    capital: 'Conakry',
    currency: 'GNF',
    masks: ['##-###-###'],
    id: 92,
  },
  {
    name: 'Guinea-Bissau',
    iso2: 'GW',
    numericCode: '624',
    phoneCode: '245',
    capital: 'Bissau',
    currency: 'XOF',
    masks: ['#-######'],
    id: 93,
  },
  {
    name: 'Guyana',
    iso2: 'GY',
    numericCode: '328',
    phoneCode: '592',
    capital: 'Georgetown',
    currency: 'GYD',
    masks: ['###-####'],
    id: 94,
  },
  {
    name: 'Haiti',
    iso2: 'HT',
    numericCode: '332',
    phoneCode: '509',
    capital: 'Port-au-Prince',
    currency: 'HTG',
    masks: ['##-##-####'],
    id: 95,
  },
  {
    name: 'Heard Island and McDonald Islands',
    iso2: 'HM',
    numericCode: '334',
    phoneCode: '672',
    capital: '',
    currency: 'AUD',
    masks: null,
    id: 96,
  },
  {
    name: 'Honduras',
    iso2: 'HN',
    numericCode: '340',
    phoneCode: '504',
    capital: 'Tegucigalpa',
    currency: 'HNL',
    masks: ['####-####'],
    id: 97,
  },
  {
    name: 'Hong Kong S.A.R.',
    iso2: 'HK',
    numericCode: '344',
    phoneCode: '852',
    capital: 'Hong Kong',
    currency: 'HKD',
    masks: ['####-####'],
    id: 98,
  },
  {
    name: 'Hungary',
    iso2: 'HU',
    numericCode: '348',
    phoneCode: '36',
    capital: 'Budapest',
    currency: 'HUF',
    masks: ['(###)###-###'],
    id: 99,
  },
  {
    name: 'Iceland',
    iso2: 'IS',
    numericCode: '352',
    phoneCode: '354',
    capital: 'Reykjavik',
    currency: 'ISK',
    masks: ['###-####'],
    id: 100,
  },
  {
    name: 'India',
    iso2: 'IN',
    numericCode: '356',
    phoneCode: '91',
    capital: 'New Delhi',
    currency: 'INR',
    masks: ['(####)###-###'],
    id: 101,
  },
  {
    name: 'Indonesia',
    iso2: 'ID',
    numericCode: '360',
    phoneCode: '62',
    capital: 'Jakarta',
    currency: 'IDR',
    masks: ['##-###-##', '##-###-###', '##-###-####', '(8##)###-###', '(8##)###-##-###'],
    id: 102,
  },
  {
    name: 'Iran',
    iso2: 'IR',
    numericCode: '364',
    phoneCode: '98',
    capital: 'Tehran',
    currency: 'IRR',
    masks: ['(###)###-####'],
    id: 103,
  },
  {
    name: 'Iraq',
    iso2: 'IQ',
    numericCode: '368',
    phoneCode: '964',
    capital: 'Baghdad',
    currency: 'IQD',
    masks: ['(###)###-####'],
    id: 104,
  },
  {
    name: 'Ireland',
    iso2: 'IE',
    numericCode: '372',
    phoneCode: '353',
    capital: 'Dublin',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 105,
  },
  {
    name: 'Israel',
    iso2: 'IL',
    numericCode: '376',
    phoneCode: '972',
    capital: 'Jerusalem',
    currency: 'ILS',
    masks: ['#-###-####', '5#-###-####'],
    id: 106,
  },
  {
    name: 'Italy',
    iso2: 'IT',
    numericCode: '380',
    phoneCode: '39',
    capital: 'Rome',
    currency: 'EUR',
    masks: ['(###)####-###'],
    id: 107,
  },
  {
    name: 'Jamaica',
    iso2: 'JM',
    numericCode: '388',
    phoneCode: '+1-876',
    capital: 'Kingston',
    currency: 'JMD',
    masks: ['(876)###-####'],
    id: 108,
  },
  {
    name: 'Japan',
    iso2: 'JP',
    numericCode: '392',
    phoneCode: '81',
    capital: 'Tokyo',
    currency: 'JPY',
    masks: ['(###)###-###', '##-####-####'],
    id: 109,
  },
  {
    name: 'Jersey',
    iso2: 'JE',
    numericCode: '832',
    phoneCode: '+44-1534',
    capital: 'Saint Helier',
    currency: 'GBP',
    masks: ['(####)####-######'],
    id: 110,
  },
  {
    name: 'Jordan',
    iso2: 'JO',
    numericCode: '400',
    phoneCode: '962',
    capital: 'Amman',
    currency: 'JOD',
    masks: ['#-####-####'],
    id: 111,
  },
  {
    name: 'Kazakhstan',
    iso2: 'KZ',
    numericCode: '398',
    phoneCode: '7',
    capital: 'Astana',
    currency: 'KZT',
    masks: ['(6##)###-##-##', '(7##)###-##-##'],
    id: 112,
  },
  {
    name: 'Kenya',
    iso2: 'KE',
    numericCode: '404',
    phoneCode: '254',
    capital: 'Nairobi',
    currency: 'KES',
    masks: ['###-######'],
    id: 113,
  },
  {
    name: 'Kiribati',
    iso2: 'KI',
    numericCode: '296',
    phoneCode: '686',
    capital: 'Tarawa',
    currency: 'AUD',
    masks: ['##-###'],
    id: 114,
  },
  {
    name: 'North Korea',
    iso2: 'KP',
    numericCode: '408',
    phoneCode: '850',
    capital: 'Pyongyang',
    currency: 'KPW',
    masks: ['###-###', '####-####', '##-###-###', '###-####-###', '191-###-####', '####-#############'],
    id: 115,
  },
  {
    name: 'South Korea',
    iso2: 'KR',
    numericCode: '410',
    phoneCode: '82',
    capital: 'Seoul',
    currency: 'KRW',
    masks: ['##-###-####'],
    id: 116,
  },
  {
    name: 'Kuwait',
    iso2: 'KW',
    numericCode: '414',
    phoneCode: '965',
    capital: 'Kuwait City',
    currency: 'KWD',
    masks: ['####-####'],
    id: 117,
  },
  {
    name: 'Kyrgyzstan',
    iso2: 'KG',
    numericCode: '417',
    phoneCode: '996',
    capital: 'Bishkek',
    currency: 'KGS',
    masks: ['(###)###-###'],
    id: 118,
  },
  {
    name: 'Laos',
    iso2: 'LA',
    numericCode: '418',
    phoneCode: '856',
    capital: 'Vientiane',
    currency: 'LAK',
    masks: ['##-###-###', '(20##)###-###'],
    id: 119,
  },
  {
    name: 'Latvia',
    iso2: 'LV',
    numericCode: '428',
    phoneCode: '371',
    capital: 'Riga',
    currency: 'EUR',
    masks: ['##-###-###'],
    id: 120,
  },
  {
    name: 'Lebanon',
    iso2: 'LB',
    numericCode: '422',
    phoneCode: '961',
    capital: 'Beirut',
    currency: 'LBP',
    masks: ['#-###-###', '##-###-###'],
    id: 121,
  },
  {
    name: 'Lesotho',
    iso2: 'LS',
    numericCode: '426',
    phoneCode: '266',
    capital: 'Maseru',
    currency: 'LSL',
    masks: ['#-###-####'],
    id: 122,
  },
  {
    name: 'Liberia',
    iso2: 'LR',
    numericCode: '430',
    phoneCode: '231',
    capital: 'Monrovia',
    currency: 'LRD',
    masks: ['##-###-###'],
    id: 123,
  },
  {
    name: 'Libya',
    iso2: 'LY',
    numericCode: '434',
    phoneCode: '218',
    capital: 'Tripolis',
    currency: 'LYD',
    masks: ['##-###-###', '21-###-####'],
    id: 124,
  },
  {
    name: 'Liechtenstein',
    iso2: 'LI',
    numericCode: '438',
    phoneCode: '423',
    capital: 'Vaduz',
    currency: 'CHF',
    masks: ['(###)###-####'],
    id: 125,
  },
  {
    name: 'Lithuania',
    iso2: 'LT',
    numericCode: '440',
    phoneCode: '370',
    capital: 'Vilnius',
    currency: 'EUR',
    masks: ['(###)##-###'],
    id: 126,
  },
  {
    name: 'Luxembourg',
    iso2: 'LU',
    numericCode: '442',
    phoneCode: '352',
    capital: 'Luxembourg',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 127,
  },
  {
    name: 'Macau S.A.R.',
    iso2: 'MO',
    numericCode: '446',
    phoneCode: '853',
    capital: 'Macao',
    currency: 'MOP',
    masks: ['####-####'],
    id: 128,
  },
  {
    name: 'Macedonia',
    iso2: 'MK',
    numericCode: '807',
    phoneCode: '389',
    capital: 'Skopje',
    currency: 'MKD',
    masks: ['##-###-###'],
    id: 129,
  },
  {
    name: 'Madagascar',
    iso2: 'MG',
    numericCode: '450',
    phoneCode: '261',
    capital: 'Antananarivo',
    currency: 'MGA',
    masks: ['##-##-#####'],
    id: 130,
  },
  {
    name: 'Malawi',
    iso2: 'MW',
    numericCode: '454',
    phoneCode: '265',
    capital: 'Lilongwe',
    currency: 'MWK',
    masks: ['1-###-###', '#-####-####'],
    id: 131,
  },
  {
    name: 'Malaysia',
    iso2: 'MY',
    numericCode: '458',
    phoneCode: '60',
    capital: 'Kuala Lumpur',
    currency: 'MYR',
    masks: ['#-###-###', '##-###-###', '(###)###-###', '##-###-####'],
    id: 132,
  },
  {
    name: 'Maldives',
    iso2: 'MV',
    numericCode: '462',
    phoneCode: '960',
    capital: 'Male',
    currency: 'MVR',
    masks: ['###-####'],
    id: 133,
  },
  {
    name: 'Mali',
    iso2: 'ML',
    numericCode: '466',
    phoneCode: '223',
    capital: 'Bamako',
    currency: 'XOF',
    masks: ['##-##-####'],
    id: 134,
  },
  {
    name: 'Malta',
    iso2: 'MT',
    numericCode: '470',
    phoneCode: '356',
    capital: 'Valletta',
    currency: 'EUR',
    masks: ['####-####'],
    id: 135,
  },
  {
    name: 'Man (Isle of)',
    iso2: 'IM',
    numericCode: '833',
    phoneCode: '+44-1624',
    capital: 'Douglas, Isle of Man',
    currency: 'GBP',
    masks: ['(####)######'],
    id: 136,
  },
  {
    name: 'Marshall Islands',
    iso2: 'MH',
    numericCode: '584',
    phoneCode: '692',
    capital: 'Majuro',
    currency: 'USD',
    masks: ['###-####'],
    id: 137,
  },
  {
    name: 'Martinique',
    iso2: 'MQ',
    numericCode: '474',
    phoneCode: '596',
    capital: 'Fort-de-France',
    currency: 'EUR',
    masks: ['(###)##-##-##'],
    id: 138,
  },
  {
    name: 'Mauritania',
    iso2: 'MR',
    numericCode: '478',
    phoneCode: '222',
    capital: 'Nouakchott',
    currency: 'MRO',
    masks: ['##-##-####'],
    id: 139,
  },
  {
    name: 'Mauritius',
    iso2: 'MU',
    numericCode: '480',
    phoneCode: '230',
    capital: 'Port Louis',
    currency: 'MUR',
    masks: ['###-####'],
    id: 140,
  },
  {
    name: 'Mayotte',
    iso2: 'YT',
    numericCode: '175',
    phoneCode: '262',
    capital: 'Mamoudzou',
    currency: 'EUR',
    masks: ['#####-####'],
    id: 141,
  },
  {
    name: 'Mexico',
    iso2: 'MX',
    numericCode: '484',
    phoneCode: '52',
    capital: 'Ciudad de México',
    currency: 'MXN',
    masks: ['##-##-####', '(###)###-####'],
    id: 142,
  },
  {
    name: 'Micronesia',
    iso2: 'FM',
    numericCode: '583',
    phoneCode: '691',
    capital: 'Palikir',
    currency: 'USD',
    masks: ['###-####'],
    id: 143,
  },
  {
    name: 'Moldova',
    iso2: 'MD',
    numericCode: '498',
    phoneCode: '373',
    capital: 'Chisinau',
    currency: 'MDL',
    masks: ['####-####'],
    id: 144,
  },
  {
    name: 'Monaco',
    iso2: 'MC',
    numericCode: '492',
    phoneCode: '377',
    capital: 'Monaco',
    currency: 'EUR',
    masks: ['##-###-###', '(###)###-###'],
    id: 145,
  },
  {
    name: 'Mongolia',
    iso2: 'MN',
    numericCode: '496',
    phoneCode: '976',
    capital: 'Ulan Bator',
    currency: 'MNT',
    masks: ['##-##-####'],
    id: 146,
  },
  {
    name: 'Montenegro',
    iso2: 'ME',
    numericCode: '499',
    phoneCode: '382',
    capital: 'Podgorica',
    currency: 'EUR',
    masks: ['##-###-###'],
    id: 147,
  },
  {
    name: 'Montserrat',
    iso2: 'MS',
    numericCode: '500',
    phoneCode: '+1-664',
    capital: 'Plymouth',
    currency: 'XCD',
    masks: ['(664)###-####'],
    id: 148,
  },
  {
    name: 'Morocco',
    iso2: 'MA',
    numericCode: '504',
    phoneCode: '212',
    capital: 'Rabat',
    currency: 'MAD',
    masks: ['##-####-###'],
    id: 149,
  },
  {
    name: 'Mozambique',
    iso2: 'MZ',
    numericCode: '508',
    phoneCode: '258',
    capital: 'Maputo',
    currency: 'MZN',
    masks: ['##-###-###'],
    id: 150,
  },
  {
    name: 'Myanmar',
    iso2: 'MM',
    numericCode: '104',
    phoneCode: '95',
    capital: 'Nay Pyi Taw',
    currency: 'MMK',
    masks: ['###-###', '#-###-###', '##-###-###'],
    id: 151,
  },
  {
    name: 'Namibia',
    iso2: 'NA',
    numericCode: '516',
    phoneCode: '264',
    capital: 'Windhoek',
    currency: 'NAD',
    masks: ['##-###-####'],
    id: 152,
  },
  {
    name: 'Nauru',
    iso2: 'NR',
    numericCode: '520',
    phoneCode: '674',
    capital: 'Yaren',
    currency: 'AUD',
    masks: ['###-####'],
    id: 153,
  },
  {
    name: 'Nepal',
    iso2: 'NP',
    numericCode: '524',
    phoneCode: '977',
    capital: 'Kathmandu',
    currency: 'NPR',
    masks: ['##-###-###'],
    id: 154,
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    iso2: 'BQ',
    numericCode: '535',
    phoneCode: '599',
    capital: 'Kralendijk',
    currency: 'USD',
    masks: null,
    id: 155,
  },
  {
    name: 'Netherlands',
    iso2: 'NL',
    numericCode: '528',
    phoneCode: '31',
    capital: 'Amsterdam',
    currency: 'EUR',
    masks: ['##-###-####'],
    id: 156,
  },
  {
    name: 'New Caledonia',
    iso2: 'NC',
    numericCode: '540',
    phoneCode: '687',
    capital: 'Noumea',
    currency: 'XPF',
    masks: ['##-####'],
    id: 157,
  },
  {
    name: 'New Zealand',
    iso2: 'NZ',
    numericCode: '554',
    phoneCode: '64',
    capital: 'Wellington',
    currency: 'NZD',
    masks: ['#-###-###', '(###)###-###', '(###)###-####'],
    id: 158,
  },
  {
    name: 'Nicaragua',
    iso2: 'NI',
    numericCode: '558',
    phoneCode: '505',
    capital: 'Managua',
    currency: 'NIO',
    masks: ['####-####'],
    id: 159,
  },
  {
    name: 'Niger',
    iso2: 'NE',
    numericCode: '562',
    phoneCode: '227',
    capital: 'Niamey',
    currency: 'XOF',
    masks: ['##-##-####'],
    id: 160,
  },
  {
    name: 'Nigeria',
    iso2: 'NG',
    numericCode: '566',
    phoneCode: '234',
    capital: 'Abuja',
    currency: 'NGN',
    masks: ['##-###-##', '##-###-###', '(###)###-####'],
    id: 161,
  },
  {
    name: 'Niue',
    iso2: 'NU',
    numericCode: '570',
    phoneCode: '683',
    capital: 'Alofi',
    currency: 'NZD',
    masks: ['####'],
    id: 162,
  },
  {
    name: 'Norfolk Island',
    iso2: 'NF',
    numericCode: '574',
    phoneCode: '672',
    capital: 'Kingston',
    currency: 'AUD',
    masks: ['3##-###'],
    id: 163,
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    numericCode: '580',
    phoneCode: '+1-670',
    capital: 'Saipan',
    currency: 'USD',
    masks: ['(670)###-####'],
    id: 164,
  },
  {
    name: 'Norway',
    iso2: 'NO',
    numericCode: '578',
    phoneCode: '47',
    capital: 'Oslo',
    currency: 'NOK',
    masks: ['(###)##-###'],
    id: 165,
  },
  {
    name: 'Oman',
    iso2: 'OM',
    numericCode: '512',
    phoneCode: '968',
    capital: 'Muscat',
    currency: 'OMR',
    masks: ['##-###-###'],
    id: 166,
  },
  {
    name: 'Pakistan',
    iso2: 'PK',
    numericCode: '586',
    phoneCode: '92',
    capital: 'Islamabad',
    currency: 'PKR',
    masks: ['(###)###-####'],
    id: 167,
  },
  {
    name: 'Palau',
    iso2: 'PW',
    numericCode: '585',
    phoneCode: '680',
    capital: 'Melekeok',
    currency: 'USD',
    masks: ['###-####'],
    id: 168,
  },
  {
    name: 'Palestinian Territory Occupied',
    iso2: 'PS',
    numericCode: '275',
    phoneCode: '970',
    capital: 'East Jerusalem',
    currency: 'ILS',
    masks: ['##-###-####'],
    id: 169,
  },
  {
    name: 'Panama',
    iso2: 'PA',
    numericCode: '591',
    phoneCode: '507',
    capital: 'Panama City',
    currency: 'PAB',
    masks: ['###-####'],
    id: 170,
  },
  {
    name: 'Papua new Guinea',
    iso2: 'PG',
    numericCode: '598',
    phoneCode: '675',
    capital: 'Port Moresby',
    currency: 'PGK',
    masks: ['(###)##-###'],
    id: 171,
  },
  {
    name: 'Paraguay',
    iso2: 'PY',
    numericCode: '600',
    phoneCode: '595',
    capital: 'Asuncion',
    currency: 'PYG',
    masks: ['(###)###-###'],
    id: 172,
  },
  {
    name: 'Peru',
    iso2: 'PE',
    numericCode: '604',
    phoneCode: '51',
    capital: 'Lima',
    currency: 'PEN',
    masks: ['(###)###-###'],
    id: 173,
  },
  {
    name: 'Philippines',
    iso2: 'PH',
    numericCode: '608',
    phoneCode: '63',
    capital: 'Manila',
    currency: 'PHP',
    masks: ['(###)###-####'],
    id: 174,
  },
  {
    name: 'Pitcairn Island',
    iso2: 'PN',
    numericCode: '612',
    phoneCode: '870',
    capital: 'Adamstown',
    currency: 'NZD',
    masks: ['###-###-###'],
    id: 175,
  },
  {
    name: 'Poland',
    iso2: 'PL',
    numericCode: '616',
    phoneCode: '48',
    capital: 'Warsaw',
    currency: 'PLN',
    masks: ['(###)###-###'],
    id: 176,
  },
  {
    name: 'Portugal',
    iso2: 'PT',
    numericCode: '620',
    phoneCode: '351',
    capital: 'Lisbon',
    currency: 'EUR',
    masks: ['##-###-####'],
    id: 177,
  },
  {
    name: 'Puerto Rico',
    iso2: 'PR',
    numericCode: '630',
    phoneCode: '+1-787 and 1-939',
    capital: 'San Juan',
    currency: 'USD',
    masks: ['(787) ### ####', '(939) ### ####'],
    id: 178,
  },
  {
    name: 'Qatar',
    iso2: 'QA',
    numericCode: '634',
    phoneCode: '974',
    capital: 'Doha',
    currency: 'QAR',
    masks: ['####-####'],
    id: 179,
  },
  {
    name: 'Reunion',
    iso2: 'RE',
    numericCode: '638',
    phoneCode: '262',
    capital: 'Saint-Denis',
    currency: 'EUR',
    masks: ['#####-####'],
    id: 180,
  },
  {
    name: 'Romania',
    iso2: 'RO',
    numericCode: '642',
    phoneCode: '40',
    capital: 'Bucharest',
    currency: 'RON',
    masks: ['##-###-####'],
    id: 181,
  },
  {
    name: 'Russia',
    iso2: 'RU',
    numericCode: '643',
    phoneCode: '7',
    capital: 'Moscow',
    currency: 'RUB',
    masks: ['(###)###-##-##'],
    id: 182,
  },
  {
    name: 'Rwanda',
    iso2: 'RW',
    numericCode: '646',
    phoneCode: '250',
    capital: 'Kigali',
    currency: 'RWF',
    masks: ['(###)###-###'],
    id: 183,
  },
  {
    name: 'Saint Helena',
    iso2: 'SH',
    numericCode: '654',
    phoneCode: '290',
    capital: 'Jamestown',
    currency: 'SHP',
    masks: ['####'],
    id: 184,
  },
  {
    name: 'Saint Kitts And Nevis',
    iso2: 'KN',
    numericCode: '659',
    phoneCode: '+1-869',
    capital: 'Basseterre',
    currency: 'XCD',
    masks: ['(869)###-####'],
    id: 185,
  },
  {
    name: 'Saint Lucia',
    iso2: 'LC',
    numericCode: '662',
    phoneCode: '+1-758',
    capital: 'Castries',
    currency: 'XCD',
    masks: ['(758)###-####'],
    id: 186,
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso2: 'PM',
    numericCode: '666',
    phoneCode: '508',
    capital: 'Saint-Pierre',
    currency: 'EUR',
    masks: ['##-####'],
    id: 187,
  },
  {
    name: 'Saint Vincent And The Grenadines',
    iso2: 'VC',
    numericCode: '670',
    phoneCode: '+1-784',
    capital: 'Kingstown',
    currency: 'XCD',
    masks: ['(784)###-####'],
    id: 188,
  },
  {
    name: 'Saint-Barthelemy',
    iso2: 'BL',
    numericCode: '652',
    phoneCode: '590',
    capital: 'Gustavia',
    currency: 'EUR',
    masks: ['###-##-##-##'],
    id: 189,
  },
  {
    name: 'Saint-Martin (French part)',
    iso2: 'MF',
    numericCode: '663',
    phoneCode: '590',
    capital: 'Marigot',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 190,
  },
  {
    name: 'Samoa',
    iso2: 'WS',
    numericCode: '882',
    phoneCode: '685',
    capital: 'Apia',
    currency: 'WST',
    masks: ['##-####'],
    id: 191,
  },
  {
    name: 'San Marino',
    iso2: 'SM',
    numericCode: '674',
    phoneCode: '378',
    capital: 'San Marino',
    currency: 'EUR',
    masks: ['####-######'],
    id: 192,
  },
  {
    name: 'Sao Tome and Principe',
    iso2: 'ST',
    numericCode: '678',
    phoneCode: '239',
    capital: 'Sao Tome',
    currency: 'STD',
    masks: ['##-#####'],
    id: 193,
  },
  {
    name: 'Saudi Arabia',
    iso2: 'SA',
    numericCode: '682',
    phoneCode: '966',
    capital: 'Riyadh',
    currency: 'SAR',
    masks: ['#-###-####', '5#-####-####'],
    id: 194,
  },
  {
    name: 'Senegal',
    iso2: 'SN',
    numericCode: '686',
    phoneCode: '221',
    capital: 'Dakar',
    currency: 'XOF',
    masks: ['##-###-####'],
    id: 195,
  },
  {
    name: 'Serbia',
    iso2: 'RS',
    numericCode: '688',
    phoneCode: '381',
    capital: 'Belgrade',
    currency: 'RSD',
    masks: ['##-###-####'],
    id: 196,
  },
  {
    name: 'Seychelles',
    iso2: 'SC',
    numericCode: '690',
    phoneCode: '248',
    capital: 'Victoria',
    currency: 'SCR',
    masks: ['#-###-###'],
    id: 197,
  },
  {
    name: 'Sierra Leone',
    iso2: 'SL',
    numericCode: '694',
    phoneCode: '232',
    capital: 'Freetown',
    currency: 'SLL',
    masks: ['##-######'],
    id: 198,
  },
  {
    name: 'Singapore',
    iso2: 'SG',
    numericCode: '702',
    phoneCode: '65',
    capital: 'Singapur',
    currency: 'SGD',
    masks: ['####-####'],
    id: 199,
  },
  {
    name: 'Slovakia',
    iso2: 'SK',
    numericCode: '703',
    phoneCode: '421',
    capital: 'Bratislava',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 200,
  },
  {
    name: 'Slovenia',
    iso2: 'SI',
    numericCode: '705',
    phoneCode: '386',
    capital: 'Ljubljana',
    currency: 'EUR',
    masks: ['##-###-###'],
    id: 201,
  },
  {
    name: 'Solomon Islands',
    iso2: 'SB',
    numericCode: '090',
    phoneCode: '677',
    capital: 'Honiara',
    currency: 'SBD',
    masks: ['#####', '###-####'],
    id: 202,
  },
  {
    name: 'Somalia',
    iso2: 'SO',
    numericCode: '706',
    phoneCode: '252',
    capital: 'Mogadishu',
    currency: 'SOS',
    masks: ['#-###-###', '##-###-###'],
    id: 203,
  },
  {
    name: 'South Africa',
    iso2: 'ZA',
    numericCode: '710',
    phoneCode: '27',
    capital: 'Pretoria',
    currency: 'ZAR',
    masks: ['##-###-####'],
    id: 204,
  },
  {
    name: 'South Georgia',
    iso2: 'GS',
    numericCode: '239',
    phoneCode: '500',
    capital: 'Grytviken',
    currency: 'GBP',
    masks: ['#####'],
    id: 205,
  },
  {
    name: 'South Sudan',
    iso2: 'SS',
    numericCode: '728',
    phoneCode: '211',
    capital: 'Juba',
    currency: 'SSP',
    masks: ['##-###-####'],
    id: 206,
  },
  {
    name: 'Spain',
    iso2: 'ES',
    numericCode: '724',
    phoneCode: '34',
    capital: 'Madrid',
    currency: 'EUR',
    masks: ['(###)###-###'],
    id: 207,
  },
  {
    name: 'Sri Lanka',
    iso2: 'LK',
    numericCode: '144',
    phoneCode: '94',
    capital: 'Colombo',
    currency: 'LKR',
    masks: ['##-###-####'],
    id: 208,
  },
  {
    name: 'Sudan',
    iso2: 'SD',
    numericCode: '729',
    phoneCode: '249',
    capital: 'Khartoum',
    currency: 'SDG',
    masks: ['##-###-####'],
    id: 209,
  },
  {
    name: 'Suriname',
    iso2: 'SR',
    numericCode: '740',
    phoneCode: '597',
    capital: 'Paramaribo',
    currency: 'SRD',
    masks: ['###-###', '###-####'],
    id: 210,
  },
  {
    name: 'Svalbard And Jan Mayen Islands',
    iso2: 'SJ',
    numericCode: '744',
    phoneCode: '47',
    capital: 'Longyearbyen',
    currency: 'NOK',
    masks: ['(###)##-###'],
    id: 211,
  },
  {
    name: 'Swaziland',
    iso2: 'SZ',
    numericCode: '748',
    phoneCode: '268',
    capital: 'Mbabane',
    currency: 'SZL',
    masks: ['##-##-####'],
    id: 212,
  },
  {
    name: 'Sweden',
    iso2: 'SE',
    numericCode: '752',
    phoneCode: '46',
    capital: 'Stockholm',
    currency: 'SEK',
    masks: ['##-###-####'],
    id: 213,
  },
  {
    name: 'Switzerland',
    iso2: 'CH',
    numericCode: '756',
    phoneCode: '41',
    capital: 'Bern',
    currency: 'CHF',
    masks: ['##-###-####'],
    id: 214,
  },
  {
    name: 'Syria',
    iso2: 'SY',
    numericCode: '760',
    phoneCode: '963',
    capital: 'Damascus',
    currency: 'SYP',
    masks: ['##-####-###'],
    id: 215,
  },
  {
    name: 'Taiwan',
    iso2: 'TW',
    numericCode: '158',
    phoneCode: '886',
    capital: 'Taipei',
    currency: 'TWD',
    masks: ['####-####', '#-####-####'],
    id: 216,
  },
  {
    name: 'Tajikistan',
    iso2: 'TJ',
    numericCode: '762',
    phoneCode: '992',
    capital: 'Dushanbe',
    currency: 'TJS',
    masks: ['##-###-####'],
    id: 217,
  },
  {
    name: 'Tanzania',
    iso2: 'TZ',
    numericCode: '834',
    phoneCode: '255',
    capital: 'Dodoma',
    currency: 'TZS',
    masks: ['##-###-####'],
    id: 218,
  },
  {
    name: 'Thailand',
    iso2: 'TH',
    numericCode: '764',
    phoneCode: '66',
    capital: 'Bangkok',
    currency: 'THB',
    masks: ['##-###-###', '##-###-####'],
    id: 219,
  },
  {
    name: 'Togo',
    iso2: 'TG',
    numericCode: '768',
    phoneCode: '228',
    capital: 'Lome',
    currency: 'XOF',
    masks: ['##-###-###'],
    id: 220,
  },
  {
    name: 'Tokelau',
    iso2: 'TK',
    numericCode: '772',
    phoneCode: '690',
    capital: '',
    currency: 'NZD',
    masks: ['####'],
    id: 221,
  },
  {
    name: 'Tonga',
    iso2: 'TO',
    numericCode: '776',
    phoneCode: '676',
    capital: 'Nukualofa',
    currency: 'TOP',
    masks: ['#####'],
    id: 222,
  },
  {
    name: 'Trinidad And Tobago',
    iso2: 'TT',
    numericCode: '780',
    phoneCode: '+1-868',
    capital: 'Port of Spain',
    currency: 'TTD',
    masks: ['(868)###-####'],
    id: 223,
  },
  {
    name: 'Tunisia',
    iso2: 'TN',
    numericCode: '788',
    phoneCode: '216',
    capital: 'Tunis',
    currency: 'TND',
    masks: ['##-###-###'],
    id: 224,
  },
  {
    name: 'Turkey',
    iso2: 'TR',
    numericCode: '792',
    phoneCode: '90',
    capital: 'Ankara',
    currency: 'TRY',
    masks: ['(###)###-####'],
    id: 225,
  },
  {
    name: 'Turkmenistan',
    iso2: 'TM',
    numericCode: '795',
    phoneCode: '993',
    capital: 'Ashgabat',
    currency: 'TMT',
    masks: ['#-###-####'],
    id: 226,
  },
  {
    name: 'Turks And Caicos Islands',
    iso2: 'TC',
    numericCode: '796',
    phoneCode: '+1-649',
    capital: 'Cockburn Town',
    currency: 'USD',
    masks: ['(249)###-###'],
    id: 227,
  },
  {
    name: 'Tuvalu',
    iso2: 'TV',
    numericCode: '798',
    phoneCode: '688',
    capital: 'Funafuti',
    currency: 'AUD',
    masks: ['2####', '90####'],
    id: 228,
  },
  {
    name: 'Uganda',
    iso2: 'UG',
    numericCode: '800',
    phoneCode: '256',
    capital: 'Kampala',
    currency: 'UGX',
    masks: ['(###)###-###'],
    id: 229,
  },
  {
    name: 'Ukraine',
    iso2: 'UA',
    numericCode: '804',
    phoneCode: '380',
    capital: 'Kiev',
    currency: 'UAH',
    masks: ['(##)###-##-##'],
    id: 230,
  },
  {
    name: 'United Arab Emirates',
    iso2: 'AE',
    numericCode: '784',
    phoneCode: '971',
    capital: 'Abu Dhabi',
    currency: 'AED',
    masks: ['#-###-####', '5#-###-####'],
    id: 231,
  },
  {
    name: 'United Kingdom',
    iso2: 'GB',
    numericCode: '826',
    phoneCode: '44',
    capital: 'London',
    currency: 'GBP',
    masks: ['##-####-####'],
    id: 232,
  },
  {
    name: 'United States',
    iso2: 'US',
    numericCode: '840',
    phoneCode: '1',
    capital: 'Washington',
    currency: 'USD',
    masks: ['(###)###-####'],
    id: 233,
  },
  {
    name: 'United States Minor Outlying Islands',
    iso2: 'UM',
    numericCode: '581',
    phoneCode: '1',
    capital: '',
    currency: 'USD',
    masks: null,
    id: 234,
  },
  {
    name: 'Uruguay',
    iso2: 'UY',
    numericCode: '858',
    phoneCode: '598',
    capital: 'Montevideo',
    currency: 'UYU',
    masks: ['#-###-##-##'],
    id: 235,
  },
  {
    name: 'Uzbekistan',
    iso2: 'UZ',
    numericCode: '860',
    phoneCode: '998',
    capital: 'Tashkent',
    currency: 'UZS',
    masks: ['##-###-####'],
    id: 236,
  },
  {
    name: 'Vanuatu',
    iso2: 'VU',
    numericCode: '548',
    phoneCode: '678',
    capital: 'Port Vila',
    currency: 'VUV',
    masks: ['#####', '##-#####'],
    id: 237,
  },
  {
    name: 'Vatican City State (Holy See)',
    iso2: 'VA',
    numericCode: '336',
    phoneCode: '379',
    capital: 'Vatican City',
    currency: 'EUR',
    masks: ['06 698#####'],
    id: 238,
  },
  {
    name: 'Venezuela',
    iso2: 'VE',
    numericCode: '862',
    phoneCode: '58',
    capital: 'Caracas',
    currency: 'VEF',
    masks: ['(###)###-####'],
    id: 239,
  },
  {
    name: 'Vietnam',
    iso2: 'VN',
    numericCode: '704',
    phoneCode: '84',
    capital: 'Hanoi',
    currency: 'VND',
    masks: ['##-####-###', '(###)####-###'],
    id: 240,
  },
  {
    name: 'Virgin Islands (British)',
    iso2: 'VG',
    numericCode: '092',
    phoneCode: '+1-284',
    capital: 'Road Town',
    currency: 'USD',
    masks: ['(284)###-####'],
    id: 241,
  },
  {
    name: 'Virgin Islands (US)',
    iso2: 'VI',
    numericCode: '850',
    phoneCode: '+1-340',
    capital: 'Charlotte Amalie',
    currency: 'USD',
    masks: ['(340)###-####'],
    id: 242,
  },
  {
    name: 'Wallis And Futuna Islands',
    iso2: 'WF',
    numericCode: '876',
    phoneCode: '681',
    capital: 'Mata Utu',
    currency: 'XPF',
    masks: ['##-####'],
    id: 243,
  },
  {
    name: 'Western Sahara',
    iso2: 'EH',
    numericCode: '732',
    phoneCode: '212',
    capital: 'El-Aaiun',
    currency: 'MAD',
    masks: null,
    id: 244,
  },
  {
    name: 'Yemen',
    iso2: 'YE',
    numericCode: '887',
    phoneCode: '967',
    capital: 'Sanaa',
    currency: 'YER',
    masks: ['#-###-###', '##-###-###', '###-###-###'],
    id: 245,
  },
  {
    name: 'Zambia',
    iso2: 'ZM',
    numericCode: '894',
    phoneCode: '260',
    capital: 'Lusaka',
    currency: 'ZMW',
    masks: ['##-###-####'],
    id: 246,
  },
  {
    name: 'Zimbabwe',
    iso2: 'ZW',
    numericCode: '716',
    phoneCode: '263',
    capital: 'Harare',
    currency: 'ZWL',
    masks: ['#-######'],
    id: 247,
  },
  {
    name: 'Kosovo',
    iso2: 'XK',
    numericCode: '926',
    phoneCode: '383',
    capital: 'Pristina',
    currency: 'EUR',
    masks: ['##-###-###', '###-###-###'],
    id: 248,
  },
  {
    name: 'Curaçao',
    iso2: 'CW',
    numericCode: '531',
    phoneCode: '599',
    capital: 'Willemstad',
    currency: 'ANG',
    masks: null,
    id: 249,
  },
  {
    name: 'Sint Maarten (Dutch part)',
    iso2: 'SX',
    numericCode: '534',
    phoneCode: '1721',
    capital: 'Philipsburg',
    currency: 'ANG',
    masks: ['(721)###-####'],
    id: 250,
  },
]
export const popularLanguages = [
  { name: 'Chinese', origin: '中文', iso2: 'zh' },
  { name: 'English', origin: 'English', iso2: 'en' },
  { name: 'Spanish', origin: 'Español', iso2: 'es' },
  { name: 'Hindi', origin: 'हिन्दी', iso2: 'hi' },
  { name: 'Arabic', origin: 'العربية', iso2: 'ar' },
  { name: 'French', origin: 'Français', iso2: 'fr' },
  { name: 'Russian', origin: 'Русский', iso2: 'ru' },
  { name: 'Portuguese', origin: 'Português', iso2: 'pt' },
  { name: 'German', origin: 'Deutsch', iso2: 'de' },
  { name: 'Japanese', origin: '日本語', iso2: 'ja' },
  { name: 'Turkish', origin: 'Türkçe', iso2: 'tr' },
  { name: 'Korean', origin: '한국어', iso2: 'ko' },
  { name: 'Italian', origin: 'Italiano', iso2: 'it' },
  { name: 'Persian', origin: 'فارسی', iso2: 'fa' },
  { name: 'Polish', origin: 'Polski', iso2: 'pl' },
  { name: 'Ukrainian', origin: 'Українська', iso2: 'uk' },
  { name: 'Dutch', origin: 'Nederlands', iso2: 'nl' },
]
